<template>
  <div class="user-page">
    <div class="content-wrapper">
      <h2>Task</h2>
    </div>
    <b-card>
      <b-row>
        <b-col md="9" xl="6">
          <div
            v-b-toggle.sidebar-task
            v-for="task in task_list"
            :key="task.id"
            class="d-flex align-items-center mt-3"
          >
            <img
              class="square img-fit flex-shrink-0 mr-4"
              :src="task.immagine"
            />

            <div>
              {{ task.tipo }}
              <h4>{{ task.titolo }}</h4>
              {{ cutDescrizione(task.descrizione) }}
            </div>
          </div>

          <b-button
            squared
            size="lg"
            class="button-dashed big-button mt-5"
            v-b-toggle.sidebar-task
            ><b-icon-plus /> Add new task</b-button
          >
        </b-col>
      </b-row>
    </b-card>
    <b-button class="fixed-button py-2" v-b-toggle.sidebar-iframe>
      <img src="../../assets/images/phone.png" class="img-fluid" />
    </b-button>
    <b-sidebar
      @hidden="resetDataSidebarIframe"
      class="right-sidebar iframe-sidebar"
      id="sidebar-iframe"
      width="500px"
      right
      shadow
      lazy
    >
      <div class="login-as">
        <h4>Login as:</h4>
        <b-select
          @change="getUrlDemo"
          :options="usersList"
          v-model="userSelected"
        >
        </b-select>
        <!-- user selectd: {{ userSelected }} || url demo: {{ url_demo }} -->
      </div>
      <div class="iframe-phone">
        <iframe
          class="iframe-phone-iframe"
          :src="url_demo"
          frameborder="0"
        ></iframe>
      </div>
    </b-sidebar>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-task"
      right
      shadow
      lazy
      @hidden="resetSidebar"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4>Seleziona Categoria Task</h4>
        {{ selected }}
        <BoxOptions class="mt-2 mb-5" v-model="selected" :options="items" />
        <Planogram v-show="selected == 'planogram'" />
        <FirmaDocumenti v-show="selected == 'firma'" />
        <Recensione v-show="selected == 'recensione'" />
        <Checklist v-show="selected == 'checklist'" />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import FirmaDocumenti from "../../components/Task/FirmaDocumenti.vue";
import Planogram from "../../components/Task/Planogram.vue";
import Recensione from "../../components/Task/Recensione.vue";
import Checklist from "../../components/Task/Checklist.vue";
export default {
  components: { Planogram, FirmaDocumenti, Recensione, Checklist },
  data() {
    return {
      selected: "planogram",
      items: [
        {
          value: "planogram",
          text: "Planogram",
          icon: "shop",
          description:
            "Schema che mostra la disposizione dei prodotti su uno scaffale. È importante per massimizzare le vendite e minimizzare lo spazio sprecato.",
        },
        {
          value: "checklist",
          text: "Checklist",
          icon: "clipboard-check",
          description: "Serie di scelte da completare.",
        },
      ],
      task_list: [
        {
          id: "l0",
          titolo: "prova 1",
          tipo: "Planogram",
          immagine: "https://picsum.photos/id/1/200/300",
          descrizione: "Bla bla bla",
        },
        {
          id: "l1",
          titolo: "prova 2",
          tipo: "Firma documenti",
          immagine: "https://picsum.photos/id/1005/200/300",
          descrizione:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ultrices turpis nisi, a ultricies lorem faucibus a.",
        },
      ],

      isNewCampaign: this.$route.params.campaignId == null,

      url_demo: "",
      usersList: [{ value: null, text: "Seleziona Utente" }],
      userSelected: null,
    };
  },
  created() {
    // this.service.readCustomEndpoint("Setting").then((res) => {
    //   if (res) {
    //     this.url_demo = res.url_demo;
    //   } else {
    //     console.log("ERROr calling Setting");
    //   }
    // });
  },
  mounted() {
    if (!this.isNewCampaign) {
      this.getUsersByCampaign();
    }
  },
  methods: {
    getUsersByCampaign() {
      this.usersList.splice(1);
      if (this.$route.params.campaignId) {
        this.service
          .readCustomEndpoint(
            "CampaignManager/GetUsersInCampaign",
            this.$route.params.campaignId
          )
          .then((res) => {
            res.forEach((element) => {
              this.usersList.push(element);
            });
          });
      } else {
        console.log("FAIL ");
      }
    },
    getUrlDemo() {
      if (this.userSelected != null) {
        this.service.readCustomEndpoint("Setting").then((res) => {
          if (res) {
            this.url_demo =
              res.url_demo +
              "/debug/" +
              this.$route.params.campaignId +
              "/" +
              this.userSelected +
              "/task";
          } else {
            console.err("ERROr calling Setting");
          }
        });
      } else {
        this.url_demo = "";
        // this.service.readCustomEndpoint("Setting").then((res) => {
        //   this.url_demo = res.url_demo + "login";
        // });
      }
    },
    resetDataSidebarIframe() {
      this.userSelected = null;
      this.url_demo = "";
    },
    cutDescrizione(descrizione) {
      if (descrizione.lenght <= 100) return descrizione;
      return descrizione.substring(0, 100).trim() + "..";
    },
  },
};
</script>
