<script>
import ChapterQuestionsEditor from "../Learning/ChapterQuestionsEditor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ChapterQuestionsEditor,
  },
  created() {
    console.log(this.$route.params);
  },
  computed: {
    ...mapGetters(["languages", "default_lang"]),
  },
};
</script>

<template>
  <div>
    <ChapterQuestionsEditor
      isChecklist
      :languages="languages"
      :default_lang="default_lang"
      :id="$route.params.id"
    />
  </div>
</template>
